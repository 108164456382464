<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Task</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="quote">
              <tbody>
                  <tr v-for="(v, k, index) in quote" :key="index" v-if="k !== 'id' && k !== 'assigned_to' && k !== 'status' && k !== 'options' && k !== 'due_date_formatted' && k !== 'object'">
                      <th v-if="k === 'assigned_to_pretty'">Assigned To</th>
                      <th v-else>{{ k|capitalize }}</th>
                      <td v-if="k === 'reference'">
                        <b-link v-if="quote.object.type === 'Complaint'" :to="{ name: 'Complaint', params: { id: quote.object.id } }">{{ v }}</b-link>
                        <b-link v-if="quote.object.type === 'Claim'" :to="{ name: 'Claim', params: { id: quote.object.id } }">{{ v }}</b-link>
                        <b-link v-if="quote.object.type === 'Onboarding'" :to="{ name: 'VetOnboarding', params: { id: quote.object.vet_id, onboarding_id: quote.object.id } }">{{ quote.reference }}</b-link>
                        <b-link v-if="quote.object.type === 'Correspondence'">{{ v }}</b-link>
                      </td>
                      <td v-else>{{ v }}</td>
                  </tr>
              </tbody>
          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="pull-left">Back</b-button>
          <b-button :to="{ name: 'TaskEdit', params: { id: quote.id } }" variant="outline-dark" class="float-right mr-2">Edit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>
  </div>
</template>

<script>
  import axios from '../../../shared/axios/auth'

  export default {
    name: 'Task',
    props: {
      caption: {
        type: String,
        default: 'Task'
      },
    },
    data: () => {
      return {
        quote: null
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getQuote() {

          let url = '/tasks/' + this.$route.params.id + '/';

        axios.get(url).then(
          response => (
            this.quote = response.data
          )
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.quote
      }
    },
    mounted() {
      this.getQuote()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
